import React from "react"
import ReactMarkdown from "react-markdown"
// import { useStaticQuery, graphql } from "gatsby"

function PrivacyText(props) {
  
    return (
        <section className="bot-privacy-text">
            <div className="bot-policy_in">
                <h3 className="bot-policy-title">{props.page.title}</h3>
                <div className="bot-policy-text">
                <ReactMarkdown source={props.page.description} linkTarget="_blank"/>
                </div>
                <p className="bot-copyright tac">© {new Date().getFullYear()} by {props.settings.companyname}</p>
            </div>
        </section>
    )
}

export default PrivacyText